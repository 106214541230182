import React from "react"
import { Card } from "react-bootstrap"

import { graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

export type Attribute = {
  trait_type: string
  value: string
}

export type Sfiggy = {
  dna: string
  nft_name: string
  nft_url: string
  file_path: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
  properties: Attribute[]
}

type SfiggyProps = {
  sfiggy: Sfiggy
}

const SfiggyCard: React.FC<SfiggyProps> = ({ sfiggy }) => (
  <Card
    style={{
      width: "15rem",
      marginBottom: 30,
    }}
  >
    <Card.Header as="h5">{sfiggy.nft_name}</Card.Header>
    <Card.Body
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link to={`/collection/dna/${sfiggy.dna}/`}>
        <GatsbyImage
          image={sfiggy.file_path.childImageSharp.gatsbyImageData}
          alt={sfiggy.nft_name}
        />
      </Link>
    </Card.Body>
  </Card>
)

export default SfiggyCard

export const query = graphql`
  fragment Sfiggy on MetadataJson {
    dna
    nft_name
    nft_url
    file_path {
      childImageSharp {
        gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
    properties {
      trait_type
      value
    }
  }
`
