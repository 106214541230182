import * as React from "react"
import { Helmet } from "react-helmet"
import { Col, Container, Row } from "react-bootstrap"

import { PageProps, graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Pager, { PageInfo } from "../components/pager"
import Seo from "../components/seo"
import SfiggyCard, { Sfiggy } from "../components/sfiggy"

type DataProps = {
  allMetadataJson: {
    nodes: [Sfiggy]
    pageInfo: PageInfo
  }
}

const CollectionPageTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={"Collection"}
        description={`Browse page ${data.allMetadataJson.pageInfo.currentPage} of the official Sfiggy NFT collection.`}
      />
      <Helmet>
        <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Collection",
    "item": "https://sfiggy.caplaz.com/roadmap/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "Page ${data.allMetadataJson.pageInfo.currentPage}"
  }]
}
  `}</script>
      </Helmet>
      <Pager
        pagination={data.allMetadataJson.pageInfo}
        onClick={(page: number) => {
          navigate(`/collection/page/${page}/`)
        }}
      />
      <Container>
        <Row>
          {data.allMetadataJson.nodes.map((art, index) => {
            return (
              <Col key={art.dna}>
                <SfiggyCard sfiggy={art} />
              </Col>
            )
          })}
        </Row>
      </Container>
      <Pager
        pagination={data.allMetadataJson.pageInfo}
        onClick={(page: number) => {
          navigate(`/collection/page/${page}/`)
        }}
      />
    </Layout>
  )
}

export default CollectionPageTemplate

export const query = graphql`
  query ArtListing($limit: Int!, $skip: Int!) {
    allMetadataJson(limit: $limit, skip: $skip) {
      nodes {
        ...Sfiggy
      }
      pageInfo {
        perPage
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
      }
    }
  }
`
