import * as React from "react"
import { Pagination } from "react-bootstrap"

const SHOW_PAGE_DELTA = 3

export type PageInfo = {
  perPage: number
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  itemCount: number
  pageCount: number
  totalCount: number
}

type Props = {
  pagination: PageInfo
  onClick: (page: number) => void
}

const Pager = ({ pagination, onClick }: Props) => {
  const showStartEllipsis = pagination.currentPage > SHOW_PAGE_DELTA
  const showEndEllipsis =
    pagination.currentPage < pagination.pageCount - SHOW_PAGE_DELTA

  return (
    <Pagination
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Pagination.First
        disabled={!pagination.hasPreviousPage}
        onClick={() => {
          onClick(1)
        }}
      />
      <Pagination.Prev
        disabled={!pagination.hasPreviousPage}
        onClick={() => {
          onClick(pagination.currentPage - 1)
        }}
      />

      {showStartEllipsis ? <Pagination.Ellipsis disabled /> : null}

      {[...Array(SHOW_PAGE_DELTA).keys()]
        .filter((v, i) => !showStartEllipsis || i > 0)
        .map(index => {
          const pageIndex = pagination.currentPage - (SHOW_PAGE_DELTA - index)

          return pageIndex > 0 ? (
            <Pagination.Item
              key={pageIndex}
              onClick={() => {
                onClick(pageIndex)
              }}
            >
              {pageIndex}
            </Pagination.Item>
          ) : null
        })}

      <Pagination.Item active>{pagination.currentPage}</Pagination.Item>

      {[...Array(SHOW_PAGE_DELTA).keys()]
        .filter((v, i) => !showStartEllipsis || i < SHOW_PAGE_DELTA - 1)
        .map(index => {
          const pageIndex = pagination.currentPage + index + 1

          return pageIndex <= pagination.pageCount ? (
            <Pagination.Item
              key={pageIndex}
              onClick={() => {
                onClick(pageIndex)
              }}
            >
              {pageIndex}
            </Pagination.Item>
          ) : null
        })}

      {showEndEllipsis ? <Pagination.Ellipsis disabled /> : null}

      <Pagination.Next
        disabled={!pagination.hasNextPage}
        onClick={() => {
          onClick(pagination.currentPage + 1)
        }}
      />
      <Pagination.Last
        disabled={!pagination.hasNextPage}
        onClick={() => {
          onClick(pagination.pageCount)
        }}
      />
    </Pagination>
  )
}

export default Pager
